<template>
  <div class="standard-sidebar climatescience-sidebar">
    <div class="nav-sticky-wraper bg-grey">
      <div class="section-navigation">
        <div><a class="cursor-pointer" @click="$refs.introduction.scrollIntoView()">Introduction</a></div>
        <div><a class="cursor-pointer" @click="$refs.core.scrollIntoView()">Core Concepts</a></div>
        <div><a class="cursor-pointer" @click="$refs.future.scrollIntoView()">The Future</a></div>
        <div><a class="cursor-pointer" @click="$refs.myths.scrollIntoView()">Myths</a></div>
      </div>
    </div>
    <article>
      <div>
        <a ref="introduction" name="introduction"/>
        <h2>Introduction</h2>
        <h3>The Science of Climate Change <video-icon name="Weather vs. Climate" href="https://player.vimeo.com/video/327151831"/></h3>
        <p class="teaser">It's not just about the weather anymore. Understanding climate science.</p>
        <img src="@/assets/images/climatescience/earth.jpg" alt="">
        <p>Behind all the news headlines about the urgency of climate change is a large and robust body of atmospheric and earth science that has been building since the 1850s, which is when the role of carbon dioxide in the planetary greenhouse effect was first discovered. Understanding the science behind what is happening to our planet and what's projected for the future is key for all communities and residents to take effective action.</p>
        <p>In this section you will gain insights on weather in a changing climate, the basic mechanics of global warming, why a few degrees really matter to the future of ecosystems and human life on the planet, and learn about climate change myths. Be sure to check out the short videos with Columbia Basin climate scientist Dr. Mel Reasoner, who brings to life many of the core concepts in this section.</p>
        <p class="font-bold">Be sure to check out the short videos with Columbia Basin climate scientist Dr. Mel Reasoner, who brings to life many of the core concepts in this section.</p>
        <learn-more>
          <learn-more-item href="https://climate.nasa.gov/">NASA: Climate Change and Global Warming</learn-more-item>
          <learn-more-item href="https://www.canada.ca/en/environment-climate-change/services/climate-change/canadian-centre-climate-services.html">Canadian Centre for Climate Services</learn-more-item>
          <learn-more-item href="https://pics.uvic.ca/education">PICS: Climate Insights 101</learn-more-item>
          <learn-more-item href="https://climatedata.ca/learn/">ClimateData.ca Learning Zone</learn-more-item>
        </learn-more>

        <div>
          <a ref="core" name="core"/>
          <h2>Core Concepts</h2>
          <img src="@/assets/images/climatescience/lightning.jpg" alt="Lightning">
          <h3>Weather, climate and variability <video-icon name="Temporal Variability" href="https://player.vimeo.com/video/327164806"/></h3>
          <p class="teaser">Climate is all about the long-term trend in weather patterns.</p>
          <p>Weather happens day to day and climate is the long-term pattern of climate conditions for a particular region or location. Because climate changes over decades, we are less likely to notice the changes. This is compounded by the fact that weather is variable and ever-changing. If you compare the temperature and precipitation records from one year to the next, no two years will ever be the same. You have to look at weather records over several decades or more to see if there's a larger pattern at work.</p>
          <p>Here in the Canadian Columbia Basin the weather data from the last 100 years show a trend towards warmer wetter winters, hotter drier summers and more extreme precipitation events. Even so, the dynamic nature of weather means that we can experience a warmer-than-normal September followed by an unusually cold October. A colder October doesn't mean that global warming has stopped, nor does a warmer-than-normal September mean that overall warming increased, so it is important to look at the long-term weather data (at least 50 years or more) to see the underlying trend over time.</p>

          <h3>Climate on a bell curve <video-icon name="Shifting Bell Curves" href="https://player.vimeo.com/video/327166139"/></h3>
          <p class="teaser">A simple bell curve can tell us a lot about climate change.</p>
          <p>Do you recall how the bell curve was used for grading tests and exams at school? It turns out that temperature for a particular location also follows the pattern of a bell curve, meaning that most of the time the temperature is close to a long-term average, and some of the time it is closer to a hot or cold extreme. When the climate for a location begins to change, so will the overall shape of the bell curve. As it gets warmer, the bell curve will "shift" in relation to the historical averages, showing how much more hot and cold weather we can expect in the future relative to the past climate. This has already happened for northern hemisphere summer temperatures. You may also wish to view the third video of this series, which provides a quick explanation of the relationship between temperature, bell curves, and climate change.</p>

          <h3>The greenhouse effect <video-icon name="How CO<sub>2</sub> Causes Warming" href="https://player.vimeo.com/video/327166493"/></h3>
          <p class="teaser">What happens when there's too much or too little carbon dioxide in the atmosphere?</p>

          <p>Carbon dioxide, also known as CO<sub>2</sub>, is a heat-trapping gas that acts like a control knob for the planet's temperature. Without CO<sub>2</sub> in the earth's atmosphere, the planet would be a very chilly -19.5°C. Too cold for life as we know it. Before the beginning of the human industrial era in the 1800s, the earth's temperature was a comfortable 15°C. Since the 1850s, which roughly coincides with the beginning of modern temperature records, human activities that release CO<sub>2</sub> and other greenhouse gases to the atmosphere, such as burning fossil fuels, clearing land and agriculture, have raised the earth's temperature by 1°C to 16°C. Current levels of CO<sub>2</sub> in the atmosphere are now higher than any time in the last five million years and are at real risk of rising to levels that will make the planet too hot for life as we know it.</p>
        </div>
        <div>
          <a ref="future" name="future"/>
          <h2>Looking to the Future</h2>
          <img src="@/assets/images/climatescience/thermometre.jpg" alt="thermometre">
          <h3>Looking ahead <video-icon name="Preparing for Change in the Basin" href="https://player.vimeo.com/video/327166944"/></h3>
          <p class="teaser">What should we be preparing for in our region?</p>
          <p>How much and how fast the earth warms will depend on the volume of greenhouse gases emitted over the next two decades from human activities like burning fossil fuels, deforestation, and food production. As the planet warms, locations in higher latitudes like Canada will experience greater increases in temperature than locations near the equator.</p>
          <p>Here in the Columbia Basin-Boundary region, we can use actual data from the past 100+ years together with future climate projections from global climate models to see what the future may hold. By the 2050s, average seasonal temperatures for the Basin are projected to be 2.4°C to 3.6°C warmer compared to the 1961-1990 period, with winters up to 15 per cent wetter and summers up to 14 per cent drier. For Basin communities and residents this means, on average, warmer wetter winters; hotter, drier summers; more extreme precipitation; and changes to local ecosystems.</p>

          <learn-more>
            <learn-more-item href="https://ourtrust.org/wp-content/uploads/downloads/2017-03_Trust_ClimateActionBooklet_Interactive_FINAL.pdf">Climate action in the Columbia Basin</learn-more-item>
            <learn-more-item href="http://www.cbrdi.ca/Projects/Climate-Adaptation">Climate Adaptation Assessments for Columbia Basin-Boundary Communities</learn-more-item>
          </learn-more>

          <h3>Modelling the Future <video-icon name="Are the Projections Believable" href="https://player.vimeo.com/video/327167518"/></h3>
          <p class="teaser">Can we rely on what the climate models are telling us?</p>

          <p>The best way to assess the accuracy of climate models is to work with the actual and modelled data for a location. Let's look at Creston as an example. Based on Environment Canada data, Creston’s actual average annual temperature increased at a rate of 1.7°C per century over the last 100 years, and 3.2°C per century since 1967. This reflects the more rapid pace of warming experienced worldwide over the last 50 years. Assuming the world makes major emission reductions, Creston’s average annual temperature by the 2050s is projected to be 2.7°C higher relative to the 1961-1990 baseline. If we extrapolate Creston’s measured rate of warming over the last 50 years, Creston would achieve 2.7°C warming in 2070, which is in the same ballpark as the projections.</p>
          <p>Applying this technique to other communities in the region tells the same story. </p>

          <h3>The Global Picture <video-icon name="Global Emission Scenarios" href="https://player.vimeo.com/video/327168163" class="pr-2"/></h3>
          <p class="teaser">Four scenarios for this century - which path will we choose? </p>
          <p>In 2015, 195 countries signed the historic Paris Agreement, agreeing to pursue efforts to limit global warming to 1.5°C, a level that would mostly avoid the challenging and long-lasting negative impacts of even more warming. The Agreement was informed by four different scenarios for global greenhouse gas emissions and temperatures.</p>
          <p>The overall message of these scenarios is both sobering and urgent: limiting global warming to 2°C or less will require global emissions to be reduced to zero by the 2060s. This highlights the importance of undertaking major emission reductions and significantly increasing the amount of carbon capture from the atmosphere through natural and technological means.</p>
          <p>As of November 2021, countries around the world have pledged reductions that would limit warming to 2.4°C by the 2050s, which is significantly lower than the worst-case emissions scenario of more than 4°C of warming by the 2050s. However, it is critical for these pledges to be put into motion and implemented fully. The time for action is now.</p>

          <learn-more>
            <learn-more-item href="https://www.youtube.com/watch?v=WiGD0OgK2ug">Ever wondered: what is the Paris Agreement and how does it work?</learn-more-item>
            <learn-more-item href="https://www.drawdown.org/climate-solutions-101/unit-1-setting-the-stage">Climate Solutions 101: Setting the Stage</learn-more-item>
          </learn-more>
        </div>
        <div>
          <a ref="myths" name="myths"/>
          <h2>Climate Myth Busting</h2>
          <img src="@/assets/images/climatescience/myth-fact.jpg" alt="myth vs fact">

          <h3>Common Myths About <br> Climate Change <video-icon name="Myth Busting: It's the Sun!" href="https://player.vimeo.com/video/327169184"/>
            <video-icon name="Myth Busting: Can we have confidence in computer models?" href="https://player.vimeo.com/video/327169511" :viewEnabled="false" />
            <video-icon name="Myth Busting: The climate has always changed, so what are we worried about?" href="https://player.vimeo.com/video/327170040" :viewEnabled="false" />
          </h3>
          <p class="teaser">Buyer beware: climate information in an era of fake news.</p>
          <p>In a time when anyone can post anything online, it is becoming increasingly challenging to accurately assess the credibility and validity of a lot of online content. There are many "myths" about climate circulating on the internet. If you are in doubt about climate information, your best strategy is to go directly to primary sources of climate science information. These include well-established and respected scientific organizations such as the International Panel on Climate Change (IPCC), NASA, the National Oceanic and Atmospheric Administration (NOAA), the American Meteorological Society, and National Science Academies worldwide, and many others. All these sources demonstrate the strong scientific consensus on climate change and the role of human activities in driving climate change.</p>
        </div>
        <div>
          <h2>About Dr. Mel Reasoner</h2>
          <p>Mel's academic career focused on past climate change and vegetation history in western North America, and he taught courses in weather and climate, climate change, paleoclimatology, and geology. He directed an international initiative (sponsored by the Swiss Academy of Science) focused on global change issues in mountain regions worldwide and became involved in the Millennium Ecosystem Assessment as a lead author of a chapter that examines atmospheric conditions and trends in mountain systems. Mel was the climate science advisor for the Columbia Basin Trust’s Communities Adapting to Climate Change Initiative and Climate Action Program. Mel co-founded Climatic Resources Consulting (CRC), which provides high-resolution forecasts of climate change impacts and works with clients to improve understanding of how climate change will impact assets and operations over the next century.</p>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import learnMore from '@/components/learnMore.vue'
import learnMoreItem from '@/components/learnMoreItem.vue'
import videoIcon from '@/components/videoIcon.vue'

const meta = {
  title: 'Climate Science',
  description: 'It\'s not just about the weather anymore. Understanding climate science.'
}

export default {
  metaInfo () {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  },
  components: {
    'learn-more': learnMore,
    'learn-more-item': learnMoreItem,
    'video-icon': videoIcon
  }
}
</script>

<style lang="scss">
.climatescience-sidebar {
  @apply pt-3;

  .section-navigation {
    @apply py-3;
  }

  p + h3 {
    @apply pt-8;
  }

  article {
    .teaser + p {
      @apply pt-0;
    }
  }
}
</style>
