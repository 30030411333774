<template>
  <span>
    <div v-if="!isMobile" :title="this.name" class="video-icon" v-observe-visibility="{ callback: handleView, throttle: 500 }">
      <svg id="vidico" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 53"><path :class="{ active: open }" @click="handleClick" d="M43 1H10c-5 0-9 4.1-9 9v33c0 5 4.1 9 9 9h33c5 0 9-4.1 9-9V10c0-4.9-4.1-9-9-9zM73.5 5.1v42.2c0 .7-2.1 1.7-3 1.8-4.8.1-16-8.8-16-8.8V12.7c.4-.3 7.1-8 15.9-8.7.8-.1 3.1.4 3.1 1.1z"/></svg>
    </div>
    <div v-else class="mt-3">
      <iframe :src="`${href}?app_id=122963`" frameborder="0" :title="name" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    href: String,
    name: String,
    viewEnabled: { type: Boolean, default: true }
  },
  data () {
    return {
    }
  },
  computed: {
    open () {
      if (this.name === this.$store.state.csVideo.name) { return true } else { return false }
    },
    isMobile () { if (window.innerWidth < 768) { return true } else { return false } }
  },
  methods: {
    handleClick () {
      this.open ? this.$store.commit('CLIMATE_VIDEO_ENABLE', '') : this.$store.commit('CLIMATE_VIDEO_ENABLE', { name: this.name, url: this.href })
    },
    handleView (isVisible) {
      if (this.viewEnabled && isVisible) {
        this.$store.commit('CLIMATE_VIDEO_ENABLE', { name: this.name, url: this.href })
        this.$store.commit('MAP_VISIBILITY', false)
      }
    }
  },
  watch: {
    open: function (newValue/*, oldValue*/) {
      console.log(this.name, 'Open Now: ', newValue)
    }
  }
}
</script>

<style lang="scss">
.video-icon {
  display: inline;
  margin-left: 0.5rem;
  cursor: pointer;
  fill: #c5e7cc;

  .active {
    fill: #34b44d;
  }

  svg {
    display: inline;
  }
}

svg {
  margin-top: -5px;
  width: 24px;
  height: 24px;
}
</style>
